// ? TabsItems
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'
// ? SERVICE
import service        from '@/services/soporte'
import authStore      from '@/store/modules/authStore'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "CreatePanel",
    components : {
        DialogForm
    },
    props : {
        dialogPanel : {
            type    : Boolean,
            default : false
        },
        isNewPanel : {
            type    : Boolean,
            default : true
        },
        oldObjectP : {
            type    : Object,
            default : () => {}
        },
        user : {
            type    : Boolean,
            default : false
        }
    },
    data : () => ({
        tipoUsuario             : 0,
        idUsuario               : 0,
        idEstatus               : 0,
        idEstatusOriginal       : 0,
        rowEstatus              : 1,
        rowDocumentar           : 0,
        rowAsignar              : 0,
        rowAtender              : 0,
        rowFinalizar            : 0,
        rowPausar               : 0,
        rowCerrar               : 0,
        title                   : "Nuevo",
        valid                   : true,
        lazy                    : false,
        idTrTicket              : -1,
        folio                   : undefined,
        titulo                  : undefined,
        // evento                  : undefined,
        evento                  : { id : 0, name: "", description : "" },
        editUsuario             : true,
        usuario                 : undefined,
        sucursal                : undefined,
        servicio                : undefined,
        descripcionTicket       : undefined,
        estatus                 : undefined,
        files                   : undefined,
        fechaU                  : undefined,
        idRlTicketResp          : -1,
        idRlRespAux             : -1,
        idRlReqTicket           : -1,
        idDtFlujoEst            : -1,
        idDsFlujoEst            : -1,
        rlTicketFM              : "",
        responsable             : undefined,
        auxiliar                : undefined,
        equipoAdic              : undefined,
        InstruccionesAsignacion : undefined,
        observacionesPausa      : undefined,
        solucionFinalizar       : undefined,
        descripcionFlujo        : undefined,
        observacionesCierre     : undefined,
        calificacionCierre      : 0,
        fechaAtencionProgramada : currentDate,
        fechaRegistroFinalizar  : currentDate,
        fechaRegistroPausa      : currentDate,
        oldObjectData           : {},
        respuestaCalif          : {},
        disabledDocumentar      : false,
        disabledAsignar         : false,
        disabledAtender         : false,
        disabledFinalizar       : false,
        disabledPausar          : false,
        disabledEstatus         : false,
        disabledClose           : false,
        arrEstatusNuevo         : [],
        arrEstatusEdicion       : [],
        items       : {
            evento   : [],
            usuario  : [],
            sucursal : [],
            servicio : []
        },
        pickerAsign     : {
            menu    : false,
            date    : currentDate
        },
        pickerAttention : {
            menu    : false,
            date    : currentDate
        },
        pickerAtencionInicio : {
            menu    : false,
            date    : currentDate
        },
        errores     : {
            folio       : false,
            titulo      : false,
            producto    : false,
            producto    : false,
            producto    : false,
            descripcion : false,
            responsable : false
        },
        rules       : {
            folio       : [],
            titulo      : [v => !!v || 'El titulo es requerido'],
            descripcion : [v => !!v || 'La descripción es requerida'],
            files       : [
                v => v == undefined ? true : v.length <= 3 || "Solo se permite un maximo de 3 archivos"
            ]
        }
    }),
    watch : {
        'oldObjectP' : function() {

            this.idTrTicket           = this.oldObjectP.id_soporteTrTicket;
            this.folio                = this.oldObjectP.folio;
            this.estatus              = this.oldObjectP.id_baseCtEstatus;
            this.idEstatus            = this.oldObjectP.id_baseCtEstatus;
            this.titulo               = this.oldObjectP.titulo;
            // this.evento               = this.oldObjectP.id_soporteCtEvento;
            this.evento               = { id : this.oldObjectP.id_soporteCtEvento, name : this.oldObjectP.evento, description : this.oldObjectP.evento };
            this.servicio             = this.oldObjectP.id_soporteCtServicio;
            this.dataSelected.usuario = this.oldObjectP.id_adminCtUser;
            this.editUsuario          = this.oldObjectP.id_adminCtUser != undefined;
            this.sucursal             = this.oldObjectP.id_adminCtSucursal;
            this.descripcionTicket    = this.oldObjectP.descripcion;
            this.fechaU               = this.oldObjectP.fechaU;
            this.dataSelected.estatus = this.idEstatus;
            this.idEstatusOriginal    = this.idEstatus;

            if( !this.isNewPanel ) // si es edición se consulta la información
                this.readDataTicket();

            this.onChange();
        },
    },
    computed : {
        ...mapGetters("Ticket", {
            dataSource : "getDataSource"
        }),
        ...mapState("Ticket", [ "dataRender", "dataSelected" ] ),
        selectedEstatus(){
            // 1:documentado, 2:asignado, 3:atendiendo, 4:finalizado, 5:cerrado, 6:cancelado, 7:pausado
            let arrEstatus = [];
            let arrEstNew = [ 1, 2, 3, 4, 7 ];
            let arrEstEdit = [ 1, 2, 3, 4, 5, 6, 7 ];
            let arrEstEditAssign = [ 2, 3, 4, 7 ];
            let arrEstEditAttend = [ 3, 4, 7 ];
            let arrEstEditPause = [ 3, 7 ]; // Se cambia por el estatus atendiendo y pausado
            let arrEstEditFinalize = [ 4, 5 ];
            if( this.isNewPanel )
                arrEstatus = this.dataRender.estatus.filter( est => arrEstNew.indexOf( est.value ) != -1 );
            if( !this.isNewPanel ){
                // arrEstatus = this.dataRender.estatus.filter( est => arrEstEdic.indexOf( est.value ) != -1  );
                if( this.idEstatusOriginal == 2 )
                    arrEstatus = this.dataRender.estatus.filter( est => arrEstEditAssign.indexOf( est.value ) != -1 );
                if( this.idEstatusOriginal == 3 )
                    arrEstatus = this.dataRender.estatus.filter( est => arrEstEditAttend.indexOf( est.value ) != -1 );
                if( this.idEstatusOriginal == 7 )
                    arrEstatus = this.dataRender.estatus.filter( est => arrEstEditPause.indexOf( est.value ) != -1 );
                if( this.idEstatusOriginal == 4 )
                    arrEstatus = this.dataRender.estatus.filter( est => arrEstEditFinalize.indexOf( est.value ) != -1 );
                if( [ 5, 6 ].indexOf( this.idEstatusOriginal ) != -1 )
                    arrEstatus = this.dataRender.estatus.filter( est => est.value == this.idEstatusOriginal );
                if( this.idEstatusOriginal == 0 )
                    arrEstatus = this.dataRender.estatus.filter( est => arrEstEdit.indexOf( est.value ) != -1 );
            }
            return arrEstatus;
        },
        itemsSucursal(){
            let itemsSucursal = this.items.sucursal;
            if( this.dataSelected.usuario != undefined ){
                itemsSucursal = itemsSucursal.filter( data => data.idUser == this.dataSelected.usuario );
            }
            return itemsSucursal;
        }
    },
    methods : {
        ...mapActions( "DialogForm", [ "setDialog" ] ),
        ...mapActions( "Ticket",  [ "setDataSource", "setDataRender" ] ),
        eventClose () {
            this.reset();
            this.evento = { id : 0, name: "", description : "" };
            this.setDialog({
                key    : "dlgPanelGral",
                status : false
            });
        },
        eventGen () {
            this.isNewPanel ? this.createTicket() : this.updateTicket();
        },
        createTicket(){
            this.$fn.setIdDocMod( 1 ); // Soporte
            if( this.validate () ) {
                let arrInfo = [];
                let {objTicket, files, objRlAuxiliar, objRlRequerimiento, objRlTicketResp} = this.buildObject();
                if( Object.keys( objTicket ).length > 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Insert", [ objTicket ], [ "id_soporteTrTicket" ] ) );

                if( Object.keys( objRlTicketResp ).length > 0 ){
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlTicketResponsable", "Insert", [ objRlTicketResp ], ["id_soporteRlTicketResponsable"] ) );
                    if( this.dataSelected.equipoAux != undefined )
                        arrInfo.push( this.$JsTable.getFormat( "soporteRlRequerimientoTicket", "Insert", objRlRequerimiento, ["id_soporteRlRequerimientoTicket"] ) );
                    if( this.dataSelected.auxiliar != undefined )
                        arrInfo.push( this.$JsTable.getFormat( "soporteRlResponsable_Auxiliar", "Insert", [ objRlAuxiliar ], ["id_soporteRlResponsable_Auxiliar"] ) );
                }

                if( this.idEstatus == 4 && this.rowFinalizar == 1 ){
                    arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( this.idTrTicket, 4 ) ], [ "id_baseDtFlujoEstatus" ] ) );
                    arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( this.descripcionFlujo ) ], [ "id_baseDsFlujoEstatus" ] ) );
                }

                if( this.idEstatus == 7 && this.rowPausar == 1 ){
                    arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( this.idTrTicket, 7 ) ], [ "id_baseDtFlujoEstatus" ] ) );
                    arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( this.descripcionFlujo ) ], [ "id_baseDsFlujoEstatus" ] ) );
                }

                if( arrInfo.length > 0 ){
                    service.SopC_panel( { data : arrInfo, files : files } ).then( result => {
                        if( this.$Msg.fn( {data : result, message : "Ticket creado correctamente" } )) {
                            this.eventClose();
                            this.readTableTicket();
                        }
                    })
                }
            }
        },
        updateTicket(){

            let arrInfo         = [];
            let trTicket        = [];
            let rlTicketResp    = []; 
            let rlRequerimiento = [];
            let rlAuxiliar      = [];
            let rlEncuetaTicket = [];
            let opcion          = "";
            if( this.validate() ){
                this.$fn.setIdDocMod( 1 ); // Soporte
                let obj = this.buildObject();

                trTicket = this.$fn.getDiffObj( [this.oldObjectData.objTicket], [obj.objTicket], ["id_soporteTrTicket", "updated_at"] );
                if( trTicket != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", trTicket, ["id_soporteTrTicket"] ) );

                opcion = Object.keys( this.oldObjectData.objRlTicketResp ).length > 0 ? "Update" : "Insert";
                rlTicketResp = this.$fn.getDiffObj( [this.oldObjectData.objRlTicketResp], [obj.objRlTicketResp], ["id_soporteRlTicketResponsable"] );
                if( rlTicketResp != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlTicketResponsable", opcion, rlTicketResp, ["id_soporteRlTicketResponsable"] ) );

                // FIXME: No se envia el objeto en la edición, causa conflicto en la diferencia de arreglos
                rlRequerimiento = this.$fn.getDiffObj( [ this.oldObjectData.objRlRequerimiento], obj.objRlRequerimiento, ["id_soporteRlRequerimientoTicket"] );
                if( rlRequerimiento != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlRequerimientoTicket", opcion, [ rlRequerimiento[0] ], ["id_soporteRlRequerimientoTicket"] ) );

                rlAuxiliar = this.$fn.getDiffObj( [this.oldObjectData.objRlAuxiliar], [obj.objRlAuxiliar], ["id_soporteRlResponsable_Auxiliar"] );
                if( rlAuxiliar != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlResponsable_Auxiliar", opcion, rlAuxiliar, ["id_soporteRlResponsable_Auxiliar"] ) );

                if( this.idEstatus == 5 && this.rowCerrar == 1 ){
                    rlEncuetaTicket.push( {
                        id_soporteRlEncuestaTicket : -1,
                        id_soporteTrTicket         : this.idTrTicket,
                        id_soporteCtEncuesta       : 1,
                        observacion                : this.observacionesCierre,
                        respuesta                  : this.calificacionCierre,
                        activo                     : 1
                    } );
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlEncuestaTicket", "Insert", rlEncuetaTicket, ["id_soporteRlEncuestaTicket"] ) );
                } 

                if( this.idEstatus == 4 && this.rowFinalizar == 1 ){
                    arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( this.idTrTicket, 4 ) ], [ "id_baseDtFlujoEstatus" ] ) );
                    arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( this.descripcionFlujo ) ], [ "id_baseDsFlujoEstatus" ] ) );
                }

                if( this.idEstatus == 7 && this.rowPausar == 1 ){
                    arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( this.idTrTicket, 7 ) ], [ "id_baseDtFlujoEstatus" ] ) );
                    arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( this.descripcionFlujo ) ], [ "id_baseDsFlujoEstatus" ] ) );
                }

                if( arrInfo.length > 0 ){
                    service.SopU_actulizarTicketGeneral( arrInfo ).then( result => {
                        if( this.$Msg.fn( {data : result, message : "Ticket general actualizado correctamente" } )) {
                            this.eventClose();
                            this.readTableTicket();
                        }
                    })
                }else
                    this.$Msg.error( "No se ha realizado ningún cambio" )
            }
        },
        getTitle() {
            return this.isNewPanel ? "Nuevo Ticket" : "Editar Ticket";
        },
        getColSize( type ) {
            return type == "sm" ? 12 :this.user ? 3 : 3;
        },
        buildObject(){
            let objTicket           = {};
            let files               = this.files;
            let objRlAuxiliar       = {};
            let objRlRequerimiento  = [];
            let objRlTicketResp     = {};

            // Creación
            objTicket = {
                id_soporteTrTicket     : this.idTrTicket,
                folio                  : this.folio,
                titulo                 : this.titulo,
                descripcion            : this.descripcionTicket,
                id_soporteCtEvento     : this.evento.id,
                id_soporteCtServicio   : this.servicio,
                id_baseCtEstatus       : this.idEstatus, // acorde al seleccionado
                id_adminCtUser         : ( this.tipoUsuario == 3 ) ? this.idUsuario : this.dataSelected.usuario,
                id_adminCtSucursal     : this.sucursal,
                id_soporteTrTicketBase : null,
                activo                 : 1
            }

            // Asignacion
            if( this.rowAsignar == 1 ){
                if( this.dataSelected.auxiliar != undefined ){
                    objRlAuxiliar = {
                        id_soporteRlResponsable_Auxiliar    :   this.idRlRespAux,
                        id_soporteRlTicketResponsable       :   this.idRlTicketResp,
                        id_adminCtUser                      :   this.dataSelected.auxiliar
                    }
                }

                if( this.dataSelected.equipoAux != null ){
                    Object.keys( this.dataSelected.equipoAux ).forEach( key => {
                        objRlRequerimiento.push({
                            id_soporteRlRequerimientoTicket :   this.idRlReqTicket,
                            id_soporteTrTicket              :   this.idTrTicket,
                            id_soporteCtRequerimiento       :   this.dataSelected.equipoAux[key]
                        })
                    });
                }
                
                if( this.dataSelected.responsable != undefined ){
                    objRlTicketResp = {
                        id_soporteRlTicketResponsable   :   this.idRlTicketResp,
                        id_soporteTrTicket              :   this.idTrTicket,
                        id_soporteCtResponsable         :   this.dataSelected.responsable,
                        fechaAsignacion                 :   this.pickerAsign.date,
                        fechaAtencionProgramada         :   this.pickerAttention.date,
                        fechaAtencionInicio             :   this.pickerAttention.date,
                        instrucciones                   :   this.InstruccionesAsignacion
                    }
                }
            }

            // Atención
            if( this.rowAtender == 1 ){
                objRlTicketResp.fechaAtencionInicio = this.pickerAtencionInicio.date;
            }

            return { objTicket, files, objRlAuxiliar, objRlRequerimiento, objRlTicketResp };
        },
        // ? Validaciones del form
        validate () {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
            this.idTrTicket = -1;
            this.rowEstatus              = 1;
            this.rowDocumentar           = 0;
            this.rowAsignar              = 0;
            this.rowAtender              = 0;
            this.rowFinalizar            = 0;
            this.rowPausar               = 0;
            this.rowCerrar               = 0;
            // this.setDataRender( { key: "estatus", data: this.arrEstatusNuevo } );
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
        onChange(  ){
            // 1:documentado, 2:asignado, 3:atendiendo, 4:finalizado, 5:cerrado, 6:cancelado, 7:pausado
            this.idEstatus = this.dataSelected.estatus;
            if( [ 1, 2, 3 ].indexOf( this.tipoUsuario ) != -1 ){
                this.rowDocumentar  = ( [ 7, 4, 3, 2, 1 ].indexOf(this.idEstatus) != -1 ) ? 1 : 0;
                this.rowAsignar     = ( [ 7, 4, 3, 2 ].indexOf(this.idEstatus) != -1 ) ? 1 : 0;
                this.rowAtender     = ( [ 7, 4, 3 ].indexOf(this.idEstatus) != -1 ) ? 1 : 0;
                this.rowFinalizar   = ( this.idEstatus == 4 ) ? 1 : 0;
                this.rowPausar      = ( this.idEstatus == 7 ) ? 1 : 0;
            }
            switch( this.tipoUsuario ){
                case 1 :
                    break;
                case 2:
                    break;
                case 3:
                    this.disabledEstatus = ( this.idEstatus == 4 ) ? false : true;
                    break;
                default:
                    break;
            }

            if( !this.isNewPanel ){
                if( this.rowDocumentar == 1 )
                    this.disabledDocumentar  = ( [ 7, 4, 3, 2 ].indexOf( this.idEstatusOriginal ) != -1 ) ? true : false;

                if( this.rowAsignar    == 1 ){
                    this.disabledAsignar = ( [ 7, 4, 3 ].indexOf( this.idEstatusOriginal ) != -1 ) ? true : false;
                    if( this.tipoUsuario == 3 )
                        this.disabledAsignar = true;
                }

                if( this.rowAtender    == 1 ){
                    this.disabledAtender = ( [ 7, 4 ].indexOf( this.idEstatusOriginal ) != -1 ) ? true : false;
                    if( this.tipoUsuario == 3 )
                        this.disabledAtender = true;
                }

                if( this.rowFinalizar == 1 ){
                    if( this.tipoUsuario == 3 )
                        this.disabledFinalizar = ( this.idEstatusOriginal == 4 ) ? true : false;
                }

                if( this.idEstatus == 5 ){
                    this.rowDocumentar  = 1;
                    this.rowAsignar     = 1;
                    this.rowAtender     = 1;
                    this.rowFinalizar   = 1;
                    this.rowCerrar      = 1;
                    this.disabledDocumentar = true;
                    this.disabledAsignar    = true;
                    this.disabledAtender    = true;
                    this.disabledFinalizar  = true;
                    this.disabledClose      = false;
                }

                if( this.idEstatusOriginal == 5 || this.idEstatusOriginal == 6 ){
                    this.rowDocumentar  = 1;
                    this.rowAsignar     = 1;
                    this.rowAtender     = 1;
                    this.rowFinalizar   = 1;
                    this.rowPausar      = 1;
                    this.rowCerrar      = 1;
                    this.disabledDocumentar = true;
                    this.disabledAsignar    = true;
                    this.disabledAtender    = true;
                    this.disabledFinalizar  = true;
                    this.disabledPausar     = true;
                    this.disabledClose      = true;
                }

            }
        },
        async readDataTicket(){
            let idEstatusTmp = 0;
            await service.SopR_dataInfoTicket( { id_soporteTrTicket : this.idTrTicket } ).then( result => {
                let dataInfoTicket = this.$JsTable.toObj( result.data.dataInfoTicket );
                if( dataInfoTicket.length > 0 ){
                    let dateAssign                  = dataInfoTicket[0].fechaAsignacion.split('T');
                    let dateAttention               = dataInfoTicket[0].fechaAtencionProgramada.split('T');
                    let dateAttentionBegin          = dataInfoTicket[0].fechaAtencionInicio.split('T');
                    this.idRlRespAux                = dataInfoTicket[0].idRlRespAux;
                    this.idRlTicketResp             = dataInfoTicket[0].idRlTicketResp;
                    this.dataSelected.auxiliar      = dataInfoTicket[0].auxiliar;
                    this.idRlReqTicket              = dataInfoTicket[0].idRlReqTicket;
                    this.dataSelected.equipoAux     = dataInfoTicket[0].equipoAdic;
                    this.dataSelected.responsable   = dataInfoTicket[0].responsable;
                    this.pickerAsign.date           = dateAssign[0];
                    this.pickerAttention.date       = dateAttention[0];
                    this.pickerAtencionInicio.date  = dateAttentionBegin[0];
                    this.InstruccionesAsignacion    = dataInfoTicket[0].instruccion;
                    this.idDtFlujoEst               = dataInfoTicket[0].idDtFlujoEst;
                    this.idDsFlujoEst               = dataInfoTicket[0].idDsFlujoEst;
                    this.descripcionFlujo           = dataInfoTicket[0].descripcion;
                    this.observacionesCierre        = dataInfoTicket[0].obsCierre;
                    this.calificacionCierre         = dataInfoTicket[0].califCierre;
                }

                // ? Estatus
                // this.setDataRender( { key: "estatus", data: this.arrEstatusEdicion } );

                this.oldObjectData = this.buildObject();
            } ) 
        }
    },
    created() {
        service.SopR_dataCreateTicket().then( result => {
            let estatusNuevo    = this.$JsTable.toObj( result.data.estatusN );
            let estatusEdicion  = this.$JsTable.toObj( result.data.estatusE );

            this.items.evento   = this.$JsTable.toObj( result.data.evento );
            this.items.usuario  = this.$JsTable.toObj( result.data.users );
            this.items.sucursal = this.$JsTable.toObj( result.data.sucursal );
            this.items.servicio = this.$JsTable.toObj( result.data.servicio );
            
            estatusNuevo.forEach(element=>{
                this.arrEstatusNuevo.push({
                    value: element.id,
                    text : element.estatus
                })
            })
            estatusEdicion.forEach(element=>{
                this.arrEstatusEdicion.push({
                    value: element.id,
                    text : element.estatus
                })
            })
        })
    },
    mounted() {
        this.tipoUsuario = authStore.getters.getUser().idPerfil; // FIXME: por pruebas
        this.idUsuario   = authStore.getters.getUser().id_adminCtUser;
        // this.tipoUsuario = 2;
        if ( this.tipoUsuario != 3 ){
            this.rules.usuario  = [v => !!v || 'El usuario es requerido'];
            this.rules.servicio = [v => !!v || 'El servicio es requerido'];
            this.rules.sucursal = [v => !!v || 'La sucursal es requerida'];
            this.rules.evento   = [v => !!v || 'El evento es requerido'];
        }
    }
}
